import { Divider, Dropdown } from "antd";
import { MenuItemType } from "antd/es/menu/interface";
import AvatarInitials from "components/AvatarInitials";
import UpgradePlanButton from "components/UpgradePlan/UpgradePlanButton";
import useAppContext from "config/AppContext/useAppContext";
import { Crisp } from "crisp-sdk-web";
import { ReactNode, useMemo, useState } from "react";
import {
  MdArrowBack,
  MdChatBubbleOutline,
  MdHelpOutline,
  MdLogout,
  MdOpenInNew,
  MdOutlineAccountCircle,
  MdOutlineAttachMoney,
  MdOutlinePersonAddAlt,
  MdSettings,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { trackEvent } from "utils/eventTracking/trackEvents";
import ReferralModal from "./ReferralModal";
import Settings from "./Settings";

type MenuItemsGroup = {
  label: string;
  icon: ReactNode;
  items: (MenuItemType & { hidden?: boolean })[];
  order: number;
  topDivider?: boolean;
};

const openInNewTab = (url: string) => window.open(url, "_blank");

const HELP_MENU_ITEMS: MenuItemsGroup = {
  label: "Help",
  icon: <MdHelpOutline size={20} />,
  order: 0,
  items: [
    {
      key: "help-center",
      label: "Help Center",
      icon: <MdOpenInNew />,
      onClick: () => openInNewTab("https://help.mymarky.ai/"),
    },
    {
      key: "community",
      label: "Marky Community",
      icon: <MdOpenInNew />,
      onClick: () =>
        openInNewTab("https://links.mymarky.ai/help-panel-facebook-community"),
    },
    {
      key: "whats-new",
      label: "What's New",
      icon: <MdOpenInNew />,
      onClick: () => openInNewTab("https://roadmap.mymarky.ai/announcements"),
    },
    {
      key: "chat",
      label: "Chat with us",
      icon: <MdChatBubbleOutline />,
      onClick: () => Crisp.chat.open(),
    },
    {
      key: "feedback",
      label: "Give Feedback",
      icon: <MdChatBubbleOutline />,
      onClick: () =>
        trackEvent("refiner_launch_survey", {
          refinerArgs: {
            surveyId: "4add4950-356f-11ef-8319-cb5fb9bc9e40",
            triggerSurvey: true,
          },
        }),
    },
  ],
};

const SidebarBottomMenu = () => {
  const { realm } = useAppContext();
  const navigate = useNavigate();
  // TODO: Add types to user state
  const { userInfo, isAgencyOwner, currentBusiness } = useAppSelector(
    (state) => state.user
  );

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);

  const USER_MENU_ITEMS: MenuItemsGroup = useMemo(() => {
    const label =
      userInfo.given_name && userInfo.family_name
        ? `${userInfo.given_name} ${userInfo.family_name}`
        : userInfo.email ?? "Guest";

    const menuItems = [
      {
        key: "account",
        label: "Manage Account",
        icon: <MdOutlineAccountCircle />,
        onClick: () => navigate("/manage-account"),
      },
      {
        key: "settings",
        label: "Settings",
        icon: <MdSettings />,
        onClick: () => setIsSettingsOpen(true),
      },
      ...(realm?.isMarky
        ? [
            ...(/professional|personal/.test(userInfo.subscription?.plan || "")
              ? [
                  {
                    onClick: () => setShowReferralModal(true),
                    icon: <MdOutlinePersonAddAlt />,
                    label: "Refer a Friend",
                    key: "refer",
                  },
                ]
              : []),
            {
              key: "affiliate",
              label: "Become an Affiliate",
              icon: <MdOutlineAttachMoney />,
              onClick: () => openInNewTab("https://mymarky.firstpromoter.com/"),
            },
          ]
        : []),
      {
        key: "logout",
        label: "Logout",
        icon: <MdLogout />,
        onClick: () => navigate("/logout"),
      },
    ];

    return {
      icon: <AvatarInitials user={userInfo} size={20} />,
      topDivider: true,
      items: menuItems,
      label,
      order: 1,
    };
  }, [userInfo, isAgencyOwner]);

  const menus = useMemo(() => {
    const menuArray = [USER_MENU_ITEMS];

    realm.isMarky && menuArray.unshift(HELP_MENU_ITEMS);

    return menuArray;
  }, [realm.isMarky, USER_MENU_ITEMS]);

  return (
    <div className="flex flex-col gap-2">
      {realm.isMarky && (
        <div className="flex justify-center gap-2 md:px-4 mb-2 w-full [&_*]:w-full">
          <UpgradePlanButton />
        </div>
      )}
      {menus.map(({ icon, label, topDivider, items, order }, index) => (
        <div key={index} style={{ order }}>
          {topDivider && (
            <div className="px-2">
              <Divider className="!mt-0 !mb-2" />
            </div>
          )}
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="topLeft"
            arrow
            overlayClassName="sidebar-bottom-menu"
          >
            <div className="sidebar-bottom-menu-trigger">
              {icon}
              <div className="overflow-hidden text-ellipsis">{label}</div>
            </div>
          </Dropdown>
        </div>
      ))}

      {isAgencyOwner && currentBusiness.id && (
        <Link to="/dashboard" className="sidebar-bottom-menu-trigger">
          <MdArrowBack size={20} />
          Back to Agency
        </Link>
      )}

      <Settings
        close={() => setIsSettingsOpen(false)}
        isOpen={isSettingsOpen}
      />
      <ReferralModal
        close={() => setShowReferralModal(false)}
        isOpen={showReferralModal}
      />
    </div>
  );
};

export default SidebarBottomMenu;
