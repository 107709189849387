import { Button } from "antd";
import CopyLinkBox from "components/Common/CopyLinkBox";
import ModalWithBorders from "components/ModalWithBorders";
import env from "config/envVars";
import { REFERRAL_PARAM, REFERRAL_REROUTE_ROUTE } from "pages/ReferralReroute";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";

const REFERRAL_INFO: { label: string; items: string[] }[] = [
  {
    label: "How it works",
    items: [
      "Share your referral link to friends who haven't used Marky before.",
      "You'll earn 1 free month for every friend you refer who subscribes to Marky using your link.",
      "Your friend will also receive 1 free month when they sign up.",
    ],
  },
  {
    label: "Additional Details",
    items: [
      "Offer only applies to users who haven't created an account with Marky yet.",
      "Rewards are automatically added to you and your friend's subscription upon your friend's purchase of a Marky paid plan.",
      "Free months will be applied towards your next billing period.",
    ],
  },
];

const ReferralModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const user = useAppSelector(selectUserInfo);

  const referralParams = new URLSearchParams({
    utm_medium: "referral",
    ...(user.id && { [REFERRAL_PARAM]: user.id }),
  });

  return (
    <ModalWithBorders
      open={isOpen}
      onCancel={close}
      centered
      title="Refer a Friend"
      footer={<Button onClick={close}>Close</Button>}
    >
      <div className="space-y-4">
        <p className="text-antd-colorTextSecondary">
          Love using Marky? Invite your friends, and you'll both enjoy{" "}
          <strong className="text-antd-colorTextBase">1 FREE month</strong> of
          Marky when they subscribe!
        </p>

        <CopyLinkBox
          label="Your Referral Link"
          link={`${env.FE_BASE_URL}${REFERRAL_REROUTE_ROUTE}?${referralParams.toString()}`}
        />

        {REFERRAL_INFO.map(({ label, items }, index) => (
          <ul key={index}>
            <label className="font-medium">{label}</label>
            {items.map((item, itemIndex) => (
              <li
                key={`item-${itemIndex}`}
                className="list-disc text-antd-colorTextSecondary ml-6"
              >
                {item}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </ModalWithBorders>
  );
};

export default ReferralModal;
